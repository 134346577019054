import React from "react"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Breadcrumb } from "components/breadcrumb"
import HubspotForm from "react-hubspot-form"

const Service = ({ location }) => {
  return (
    <Layout>
      <Seo
        pagetitle="資料ダウンロード"
        pagepath={location.pathname}
        pagedesc="資料ダウンロード | PARKLoT(パークロット)は「月額制」で「何回でも」実施できるTwitterキャンペーンツールを提供しています。"
      />
      <Breadcrumb title="資料ダウンロード" />
      <main className="information">
        <section className="information__section l-container">
          {/* <h1 className="information__title"></h1> */}
          <HubspotForm
            portalId="8283520"
            formId="60fb3202-005d-4896-bdf4-2fb47c895433"
            onSubmit={() => console.log("Submit!")}
            onReady={form => console.log("Form ready!")}
            loading={<div>Loading...</div>}
          />
        </section>
      </main>
    </Layout>
  )
}

export default Service
